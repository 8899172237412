.option-menu {
    visibility: hidden;
    pointer-events: none;

    display: inline-block;
    background-color: black;
    z-index: 9000;
    min-width: 10rem;
    padding: 0.5rem 0;
    font-size: 1rem;
    list-style: none;
    background-clip: padding-box;
    border-radius: 0.25rem;
    border: 1px solid rgba(255, 255, 255, 0.15);
}
.option-menu.show {
    pointer-events: all;
    visibility: visible;
}
.option-menu[data-popper-reference-hidden="true"] {
    visibility: hidden;
    pointer-events: none;
}
.option-menu .menu-item {
    display: block;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    white-space: nowrap;
    color: var(--light);
    text-decoration: none;
    background-color: transparent;
}
.option-menu .menu-item:hover,
.option-menu .menu-item:focus {
    color: var(--light);
    background-color: var(--dark);
}
.option-menu .menu-item:active {
    background-color: var(--primary);
}

.option-menu .menu-item:not([data-action]) {
    position: relative;
}
.option-menu .menu-item[role="group-title"] > * {
    position: absolute;
    display: none !important;
}
.option-menu .menu-item[role="group-title"]:hover .item-grid {
    display: grid !important;
}
.option-menu .menu-item .item-grid {
    display: grid;
    grid-row-gap: 0.3rem;
    grid-column-gap: 0.3rem;
    grid-template-columns: repeat(3, auto);

    margin: 0;
    padding: 0.5rem;
    border-radius: 0.25rem;
    background-color: black;
    border: 1px solid var(--dark);

    left: 0;
    bottom: 0;
    transform: translate(-100%, calc(50% - 1rem));
}
.option-menu .menu-item .item-grid .color-item {
    list-style: none;
    display: inline-block;
    border-radius: 50%;
    padding: 0.75rem;

    border-width: 1.5px;
    border-style: solid;
}

.option-menu .menu-item .item-grid .color-item.default {
    border-color: var(--dark);
    background-color: var(--dark);
}

.option-menu .menu-item .item-grid .color-item.red {
    border-color: var(--danger);
    background-color: var(--danger);
}

.option-menu .menu-item .item-grid .color-item.danger {
    border-color: var(--danger);
    background-color: var(--danger);
}

.option-menu .menu-item .item-grid .color-item.blue {
    border-color: var(--blue);
    background-color: var(--blue);
}

.option-menu .menu-item .item-grid .color-item.purple {
    border-color: var(--purple);
    background-color: var(--purple);
}

.option-menu .menu-item .item-grid .color-item.pink {
    border-color: var(--pink);
    background-color: var(--pink);
}

.option-menu .menu-item .item-grid .color-item.orange {
    border-color: var(--orange);
    background-color: var(--orange);
}

.option-menu .menu-item .item-grid .color-item.yellow {
    border-color: var(--yellow);
    background-color: var(--yellow);
}

.option-menu .menu-item .item-grid .color-item.green {
    border-color: var(--green);
    background-color: var(--green);
}

.option-menu .menu-item .item-grid .color-item.cyan {
    border-color: var(--cyan);
    background-color: var(--cyan);
}

.option-menu .menu-item .item-grid .color-item:hover {
    cursor: pointer;
    border-color: var(--light);
}

.option-menu .menu-item .item-grid .color-item.active {
    position: relative;
}
.option-menu .menu-item .item-grid .color-item.active {
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAANFJREFUOE/tkjsKwlAQRc/F0s96tNDW2hVYuQYLmwQRBLdgJWjhAkTcgNYuRRcw8iCBSN7zJZIyU87nMHPnioZDDfNogdUUNbMhcAIMmEl65pO1NTSzEXAFBhlkL2nxF9ADc5y5pIMXaGY9YAV0gLWkd94YgKWSkqJQXyeb2RZYZg0PYCrpVRXm5n4BXf0OpMC5oJnLlzYLndwHboD7YiiCsNKGLmFmXeACTDzERJLbOBhe2wSgUZh3w8JXnc+OwBjYSNpVsX1tY8egLTCmULz+AWBmQBUaocqMAAAAAElFTkSuQmCC");
}

.option-menu + .menu-backdrop {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0.5;
}
.option-menu.show + .menu-backdrop {
    position: fixed;
    z-index: 8000;
}
