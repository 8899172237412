.sm-icon {
    padding: 20px;
    display: inline-block;
    background-size: 20px;
    background-repeat: no-repeat;
    background-position: center;
}

.sort-icon {
    padding: 0 10px;
    background-repeat: no-repeat;
    background-position: center;
}

.ic-alphabet-asc {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1zb3J0LWFscGhhLWRvd24iIGZpbGw9IiMwMDdiZmYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTQgMmEuNS41IDAgMCAxIC41LjV2MTFhLjUuNSAwIDAgMS0xIDB2LTExQS41LjUgMCAwIDEgNCAyeiIvPg0KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjM1NCAxMS4xNDZhLjUuNSAwIDAgMSAwIC43MDhsLTIgMmEuNS41IDAgMCAxLS43MDggMGwtMi0yYS41LjUgMCAwIDEgLjcwOC0uNzA4TDQgMTIuNzkzbDEuNjQ2LTEuNjQ3YS41LjUgMCAwIDEgLjcwOCAweiIvPg0KICA8cGF0aCBkPSJNOS42NjQgN2wuNDE4LTEuMzcxaDEuNzgxTDEyLjI4MSA3aDEuMTIxbC0xLjc4LTUuMzMyaC0xLjIzNUw4LjU5NyA3aDEuMDY3ek0xMSAyLjY4N2wuNjUyIDIuMTU3aC0xLjM1MWwuNjUyLTIuMTU3SDExek05LjAyNyAxNGgzLjkzNHYtLjg2N2gtMi42NDV2LS4wNTVsMi41NjctMy43MTl2LS42OTFIOS4wOTh2Ljg2N2gyLjUwN3YuMDU1bC0yLjU3OCAzLjcxOVYxNHoiLz4NCjwvc3ZnPg==");
}

.ic-alphabet-desc {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1zb3J0LWFscGhhLXVwIiBmaWxsPSIjMDA3YmZmIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTQgMTRhLjUuNSAwIDAgMCAuNS0uNXYtMTFhLjUuNSAwIDAgMC0xIDB2MTFhLjUuNSAwIDAgMCAuNS41eiIvPg0KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTYuMzU0IDQuODU0YS41LjUgMCAwIDAgMC0uNzA4bC0yLTJhLjUuNSAwIDAgMC0uNzA4IDBsLTIgMmEuNS41IDAgMSAwIC43MDguNzA4TDQgMy4yMDdsMS42NDYgMS42NDdhLjUuNSAwIDAgMCAuNzA4IDB6Ii8+DQogICAgPHBhdGggZD0iTTkuNjY0IDdsLjQxOC0xLjM3MWgxLjc4MUwxMi4yODEgN2gxLjEyMWwtMS43OC01LjMzMmgtMS4yMzVMOC41OTcgN2gxLjA2N3pNMTEgMi42ODdsLjY1MiAyLjE1N2gtMS4zNTFsLjY1Mi0yLjE1N0gxMXpNOS4wMjcgMTRoMy45MzR2LS44NjdoLTIuNjQ1di0uMDU1bDIuNTY3LTMuNzE5di0uNjkxSDkuMDk4di44NjdoMi41MDd2LjA1NWwtMi41NzggMy43MTlWMTR6Ii8+DQo8L3N2Zz4=");
}

.ic-date-asc {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1zb3J0LW51bWVyaWMtZG93biIgZmlsbD0iIzAwN2JmZiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik00IDJhLjUuNSAwIDAgMSAuNS41djExYS41LjUgMCAwIDEtMSAwdi0xMUEuNS41IDAgMCAxIDQgMnoiLz4NCiAgICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjM1NCAxMS4xNDZhLjUuNSAwIDAgMSAwIC43MDhsLTIgMmEuNS41IDAgMCAxLS43MDggMGwtMi0yYS41LjUgMCAwIDEgLjcwOC0uNzA4TDQgMTIuNzkzbDEuNjQ2LTEuNjQ3YS41LjUgMCAwIDEgLjcwOCAweiIvPg0KICAgIDxwYXRoIGQ9Ik0xMi40MzggN1YxLjY2OEgxMS4zOWwtMS4yNjIuOTA2di45NjlsMS4yMS0uODZoLjA1MlY3aDEuMDQ2em0tMi44NCA1LjgyYy4wNTQuNjIxLjYyNSAxLjI3OCAxLjc2MSAxLjI3OCAxLjQyMiAwIDIuMTQ1LS45OCAyLjE0NS0yLjg0OCAwLTIuMDUtLjk3My0yLjY4OC0yLjA2My0yLjY4OC0xLjEyNSAwLTEuOTcyLjY4OC0xLjk3MiAxLjgzNiAwIDEuMTQ1LjgwOCAxLjc1OCAxLjcxOSAxLjc1OC42OSAwIDEuMTEzLS4zNTEgMS4yNjEtLjc0MmguMDU5Yy4wMzEgMS4wMjctLjMwOSAxLjg1Ni0xLjEzMyAxLjg1Ni0uNDMgMC0uNzE1LS4yMjctLjc3My0uNDVIOS41OTh6bTIuNzU3LTIuNDNjMCAuNjM3LS40My45NzMtLjkzMy45NzMtLjUxNiAwLS45MzQtLjM0LS45MzQtLjk4IDAtLjYyNS40MDctMSAuOTI2LTEgLjU0MyAwIC45NDEuMzc1Ljk0MSAxLjAwOHoiLz4NCjwvc3ZnPg==");
}

.ic-date-desc {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1zb3J0LW51bWVyaWMtdXAiIGZpbGw9IiMwMDdiZmYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNCAxNGEuNS41IDAgMCAwIC41LS41di0xMWEuNS41IDAgMCAwLTEgMHYxMWEuNS41IDAgMCAwIC41LjV6Ii8+DQogICAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNNi4zNTQgNC44NTRhLjUuNSAwIDAgMCAwLS43MDhsLTItMmEuNS41IDAgMCAwLS43MDggMGwtMiAyYS41LjUgMCAxIDAgLjcwOC43MDhMNCAzLjIwN2wxLjY0NiAxLjY0N2EuNS41IDAgMCAwIC43MDggMHoiLz4NCiAgICA8cGF0aCBkPSJNMTIuNDM4IDdWMS42NjhIMTEuMzlsLTEuMjYyLjkwNnYuOTY5bDEuMjEtLjg2aC4wNTJWN2gxLjA0NnptLTIuODQgNS44MmMuMDU0LjYyMS42MjUgMS4yNzggMS43NjEgMS4yNzggMS40MjIgMCAyLjE0NS0uOTggMi4xNDUtMi44NDggMC0yLjA1LS45NzMtMi42ODgtMi4wNjMtMi42ODgtMS4xMjUgMC0xLjk3Mi42ODgtMS45NzIgMS44MzYgMCAxLjE0NS44MDggMS43NTggMS43MTkgMS43NTguNjkgMCAxLjExMy0uMzUxIDEuMjYxLS43NDJoLjA1OWMuMDMxIDEuMDI3LS4zMDkgMS44NTYtMS4xMzMgMS44NTYtLjQzIDAtLjcxNS0uMjI3LS43NzMtLjQ1SDkuNTk4em0yLjc1Ny0yLjQzYzAgLjYzNy0uNDMuOTczLS45MzMuOTczLS41MTYgMC0uOTM0LS4zNC0uOTM0LS45OCAwLS42MjUuNDA3LTEgLjkyNi0xIC41NDMgMCAuOTQxLjM3NS45NDEgMS4wMDh6Ii8+DQo8L3N2Zz4=");
}

.ic-back {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pg0KICAgIDwhRE9DVFlQRSBzdmcgIFBVQkxJQyAnLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4nICAnaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkJz4NCiAgICA8c3ZnIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0iIzAwN2JmZiIgaWQ9IkxheWVyXzEiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDMyIDMyOyIgdmVyc2lvbj0iMS4xIiB4bWw6c3BhY2U9InByZXNlcnZlIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4NCiAgICA8cGF0aCBkPSJNMjgsMTRIOC44bDQuNjItNC42MkMxMy44MTQsOC45ODYsMTQsOC41MTYsMTQsOGMwLTAuOTg0LTAuODEzLTItMi0yYy0wLjUzMSwwLTAuOTk0LDAuMTkzLTEuMzgsMC41OGwtNy45NTgsNy45NTggIEMyLjMzNCwxNC44NjYsMiwxNS4yNzEsMiwxNnMwLjI3OSwxLjA4LDAuNjQ2LDEuNDQ3bDcuOTc0LDcuOTczQzExLjAwNiwyNS44MDcsMTEuNDY5LDI2LDEyLDI2YzEuMTg4LDAsMi0xLjAxNiwyLTIgIGMwLTAuNTE2LTAuMTg2LTAuOTg2LTAuNTgtMS4zOEw4LjgsMThIMjhjMS4xMDQsMCwyLTAuODk2LDItMlMyOS4xMDQsMTQsMjgsMTR6Ii8+DQo8L3N2Zz4=");
}

.header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: #191d21;
    box-shadow: 0 10px 10px -10px var(--dark);
}

.header .search-box {
    width: 38px;
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiBmaWxsPSIjMDA3YmZmIiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1zZWFyY2giIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTEwLjQ0MiAxMC40NDJhMSAxIDAgMCAxIDEuNDE1IDBsMy44NSAzLjg1YTEgMSAwIDAgMS0xLjQxNCAxLjQxNWwtMy44NS0zLjg1YTEgMSAwIDAgMSAwLTEuNDE1eiIvPg0KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik02LjUgMTJhNS41IDUuNSAwIDEgMCAwLTExIDUuNSA1LjUgMCAwIDAgMCAxMXpNMTMgNi41YTYuNSA2LjUgMCAxIDEtMTMgMCA2LjUgNi41IDAgMCAxIDEzIDB6Ii8+DQo8L3N2Zz4=");
}

.header .search.ui-autocomplete {
    color: var(--light);
    background-color: var(--dark);
}

.header .search.ui-autocomplete .ui-state-active {
    margin: 0px;
    background-color: var(--primary);
}

.header .input-group .btn {
    width: 38px;
    background-position: center;
    background-repeat: no-repeat;
}

.header .ic-back {
    right: 0;
    z-index: 10;
    cursor: pointer;
    position: absolute;
}

.header .btn-refresh {
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pg0KPHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiBmaWxsPSIjMDA3YmZmIiB2aWV3Qm94PSIwIDAgMjQgMjQiIGNsYXNzPSJiaSBiaS1zZWFyY2giIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+DQogICAgPHBhdGggZmlsbD0iIzAwN2JmZiIgZD0iTTEyLDRhOCw4LDAsMCwxLDQuOTg1LDEuNzU4SDE1LjI0MmExLDEsMCwwLDAsMCwyaDRhMSwxLDAsMCwwLDEtMXYtNGExLDEsMCwxLDAtMiwwVjQuMjA2QTkuOTgzLDkuOTgzLDAsMCwwLDIsMTJhMSwxLDAsMCwwLDIsMEE4LjAwOSw4LjAwOSwwLDAsMSwxMiw0WiIvPg0KICAgIDxwYXRoIGZpbGw9IiMwMDdiZmYiIGQ9Ik0yMSwxMWExLDEsMCwwLDAtMSwxQTcuOTg2LDcuOTg2LDAsMCwxLDcuMDE1LDE4LjI0Mkg4Ljc1N2ExLDEsMCwxLDAsMC0yaC00YTEsMSwwLDAsMC0xLDF2NGExLDEsMCwwLDAsMiwwVjE5Ljc5NEE5Ljk4NCw5Ljk4NCwwLDAsMCwyMiwxMiwxLDEsMCwwLDAsMjEsMTFaIi8+DQo8L3N2Zz4=");
}

.header .btn-add-note {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiBmaWxsPSIjMDA3YmZmIiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1wbHVzIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNiA4QTggOCAwIDEgMSAwIDhhOCA4IDAgMCAxIDE2IDB6TTguNSA0LjVhLjUuNSAwIDAgMC0xIDB2M2gtM2EuNS41IDAgMCAwIDAgMWgzdjNhLjUuNSAwIDAgMCAxIDB2LTNoM2EuNS41IDAgMCAwIDAtMWgtM3YtM3oiLz4NCjwvc3ZnPg==");
}

.header .btn-view-mode.grid-view {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiBmaWxsPSIjMDA3YmZmIiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1saXN0LXVsIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xIDIuNUExLjUgMS41IDAgMCAxIDIuNSAxaDNBMS41IDEuNSAwIDAgMSA3IDIuNXYzQTEuNSAxLjUgMCAwIDEgNS41IDdoLTNBMS41IDEuNSAwIDAgMSAxIDUuNXYtM3pNMi41IDJhLjUuNSAwIDAgMC0uNS41djNhLjUuNSAwIDAgMCAuNS41aDNhLjUuNSAwIDAgMCAuNS0uNXYtM2EuNS41IDAgMCAwLS41LS41aC0zem02LjUuNUExLjUgMS41IDAgMCAxIDEwLjUgMWgzQTEuNSAxLjUgMCAwIDEgMTUgMi41djNBMS41IDEuNSAwIDAgMSAxMy41IDdoLTNBMS41IDEuNSAwIDAgMSA5IDUuNXYtM3ptMS41LS41YS41LjUgMCAwIDAtLjUuNXYzYS41LjUgMCAwIDAgLjUuNWgzYS41LjUgMCAwIDAgLjUtLjV2LTNhLjUuNSAwIDAgMC0uNS0uNWgtM3pNMSAxMC41QTEuNSAxLjUgMCAwIDEgMi41IDloM0ExLjUgMS41IDAgMCAxIDcgMTAuNXYzQTEuNSAxLjUgMCAwIDEgNS41IDE1aC0zQTEuNSAxLjUgMCAwIDEgMSAxMy41di0zem0xLjUtLjVhLjUuNSAwIDAgMC0uNS41djNhLjUuNSAwIDAgMCAuNS41aDNhLjUuNSAwIDAgMCAuNS0uNXYtM2EuNS41IDAgMCAwLS41LS41aC0zem02LjUuNUExLjUgMS41IDAgMCAxIDEwLjUgOWgzYTEuNSAxLjUgMCAwIDEgMS41IDEuNXYzYTEuNSAxLjUgMCAwIDEtMS41IDEuNWgtM0ExLjUgMS41IDAgMCAxIDkgMTMuNXYtM3ptMS41LS41YS41LjUgMCAwIDAtLjUuNXYzYS41LjUgMCAwIDAgLjUuNWgzYS41LjUgMCAwIDAgLjUtLjV2LTNhLjUuNSAwIDAgMC0uNS0uNWgtM3oiLz4NCjwvc3ZnPg==");
}

.header .btn-view-mode.list-view {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiBmaWxsPSIjMDA3YmZmIiB2aWV3Qm94PSIwIDAgMTYgMTYiIGNsYXNzPSJiaSBiaS1saXN0LXVsIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPg0KICA8cGF0aCBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik01IDExLjVhLjUuNSAwIDAgMSAuNS0uNWg5YS41LjUgMCAwIDEgMCAxaC05YS41LjUgMCAwIDEtLjUtLjV6bTAtNGEuNS41IDAgMCAxIC41LS41aDlhLjUuNSAwIDAgMSAwIDFoLTlhLjUuNSAwIDAgMS0uNS0uNXptMC00YS41LjUgMCAwIDEgLjUtLjVoOWEuNS41IDAgMCAxIDAgMWgtOWEuNS41IDAgMCAxLS41LS41em0tMyAxYTEgMSAwIDEgMCAwLTIgMSAxIDAgMCAwIDAgMnptMCA0YTEgMSAwIDEgMCAwLTIgMSAxIDAgMCAwIDAgMnptMCA0YTEgMSAwIDEgMCAwLTIgMSAxIDAgMCAwIDAgMnoiLz4NCjwvc3ZnPg==");
}

.header .btn-sort {
    cursor: default !important;
    background-color: black;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjVweCIgaGVpZ2h0PSIyNXB4IiBmaWxsPSJ3aGl0ZSIgdmlld0JveD0iMCAwIDE2IDE2IiBjbGFzcz0iYmkgYmktbW9vbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMiAxMC41YS41LjUgMCAwIDEgLjUtLjVoM2EuNS41IDAgMCAxIDAgMWgtM2EuNS41IDAgMCAxLS41LS41em0wLTNhLjUuNSAwIDAgMSAuNS0uNWg3YS41LjUgMCAwIDEgMCAxaC03YS41LjUgMCAwIDEtLjUtLjV6bTAtM2EuNS41IDAgMCAxIC41LS41aDExYS41LjUgMCAwIDEgMCAxaC0xMWEuNS41IDAgMCAxLS41LS41eiIvPg0KPC9zdmc+");
    border-top-right-radius: 0.25rem !important;
    border-bottom-right-radius: 0.25rem !important;
}

.header .dropdown-menu.sort .dropdown-item {
    cursor: pointer;
}

.header .dropdown-menu.sort .dropdown-item.active {
    color: white;
    cursor: default;
    background-color: var(--dark);
}

.react-autosuggest__container {
    position: relative;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    z-index: 100;
    top: 40px;
    left: 0;
    right: 0;
    border: 1px solid var(--dark);
    background-color: #191d21;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 0.5rem 0.75rem;
}

.react-autosuggest__suggestion--highlighted {
    background-color: black;
}
