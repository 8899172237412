.note {
    display: flex;
    align-items: center;
}

.note .checkbox {
    position: relative;
    min-width: 20px;
    min-height: 20px;
    background-color: var(--note-color, var(--primary));
    -webkit-mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiBmaWxsPSJ2YXIoLS1ub3RlLWNvbG9yKSIgIHZpZXdCb3g9IjAgMCAxNiAxNiIgY2xhc3M9ImJpIGJpLXNxdWFyZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICAgIDxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgZD0iTTE0IDFIMmExIDEgMCAwIDAtMSAxdjEyYTEgMSAwIDAgMCAxIDFoMTJhMSAxIDAgMCAwIDEtMVYyYTEgMSAwIDAgMC0xLTF6TTIgMGEyIDIgMCAwIDAtMiAydjEyYTIgMiAwIDAgMCAyIDJoMTJhMiAyIDAgMCAwIDItMlYyYTIgMiAwIDAgMC0yLTJIMnoiLz4KPC9zdmc+")
        center / contain no-repeat;
}
.note .checkbox.loading {
    pointer-events: none;
    -webkit-mask: unset !important;
    background-color: unset !important;
}

.note .checkbox.loading:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-grow;
    background-color: rgba(255, 255, 255, 0.2);
}

.note .checkbox.checked {
    -webkit-mask: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiBmaWxsPSJ2YXIoLS1ub3RlLWNvbG9yKSIgdmlld0JveD0iMCAwIDE2IDE2IiBjbGFzcz0iYmkgYmktY2hlY2stc3F1YXJlLWZpbGwiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMiAwYTIgMiAwIDAgMC0yIDJ2MTJhMiAyIDAgMCAwIDIgMmgxMmEyIDIgMCAwIDAgMi0yVjJhMiAyIDAgMCAwLTItMkgyem0xMC4wMyA0Ljk3YS43NS43NSAwIDAgMC0xLjA4LjAyMkw3LjQ3NyA5LjQxNyA1LjM4NCA3LjMyM2EuNzUuNzUgMCAwIDAtMS4wNiAxLjA2TDYuOTcgMTEuMDNhLjc1Ljc1IDAgMCAwIDEuMDc5LS4wMmwzLjk5Mi00Ljk5YS43NS43NSAwIDAgMC0uMDEtMS4wNXoiLz4KPC9zdmc+")
        center / contain no-repeat;
}

.note .content {
    flex-grow: 1;
    margin-left: 10px;
    overflow: hidden;
    max-height: 1.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.note .option {
    z-index: 1;
    visibility: hidden;
    min-height: 20px;
    position: relative;
}

.note .option svg {
    z-index: -1;
    position: relative;
}

.note .option path {
    fill: var(--note-color, var(--primary));
}

.note .option,
.note .checked,
.note .checkbox {
    cursor: pointer;
}

.note-preview {
    font-size: 14px;
}

.note-item {
    --note-color: var(--default);
    border: 1px solid var(--note-color, var(--dark)) !important;
}

.note-item .text-sync-color {
    color: var(--note-color, var(--primary));
}

.note-preview .note-group .list-group-item {
    overflow-wrap: break-word;
}

.list-group-item:hover .note .option {
    visibility: visible !important;
}

.list-group-item + .list-group-item {
    border-top-width: 0;
}

.list-group-item .card {
    margin-top: 5px;
}

.list-group-item .card .card-center {
    padding: 10px 0;
    text-align: center;
}

.note-preview .card-body > *:not(.link):empty {
    display: none;
}

.note-preview .card-body > *:not(.link) + *:not(.link) {
    margin-top: 0.5rem;
}

.note-preview .card-img-top {
    height: 150px;
    background-size: cover;
    background-position: center;
}

.note-preview .title,
.note-preview .link,
.note-preview .link:hover,
.note-preview .link:visited {
    color: white;
    font-weight: bold;
    text-decoration: none;
}

.note-preview a.link {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.note-preview .description {
    font-size: 13px;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.note-preview .description p {
    margin-bottom: 0.25rem;
}

.note-preview .site-name {
    display: block;
    color: var(--gray);
}

.note-preview.collapsing,
.note-preview.collapse.show {
    margin-top: 12px;
}

.note-group.card {
    margin-top: 5px;
    border: 1px solid #444444;
}
