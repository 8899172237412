#input-modal .card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

#input-modal .card-img-top {
    height: 150px;
    background-size: cover;
    background-position: center;
}

#input-modal .link,
#input-modal .link:hover,
#input-modal .link:visited {
    color: white;
    font-weight: bold;
    text-decoration: none;
}

#input-modal .description {
    font-size: 13px;
    overflow: hidden;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

#input-modal .site-name {
    color: rgba(255, 255, 255, 0.4);
}

#input-modal .collapsing,
#input-modal .collapse.show {
    margin-top: 12px;
}

#input-modal .padding-sm {
    padding: 5px 20px;
}

#input-modal .note-group [data-role="title"] {
    font-weight: bold;
}

#input-modal [contenteditable][placeholder]:empty:before {
    content: attr(placeholder);
    color: gray;
    background-color: transparent;
}

#input-modal [contenteditable].is-invalid {
    position: relative;
}

#input-modal [contenteditable].is-invalid + .invalid-feedback {
    display: block;
    position: absolute;
    top: 0;
    right: 0.5rem;
    bottom: 0;
    width: 16px; /* icon width */
    background-position: center;
    background-repeat: no-repeat;
    background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAXVJREFUOE+lk00vA1EYhc+5Yu0rsRcdErGh7QgRm5pK8B9shETEgvgVQmJB0I0/UaIfiYiF6rAhFmZYi4SNleC+0luafk3TpLOZzLnveeY9772XaPFhI79nx8ZBipXLXAXVBQK8sdgQtLo3RqWHrVz2oR4kEPBoO0klmDUmQTLkpuebBjxH4o6mpMoNFMz1u+mTakhNB2443N6puu4ADlYUU3x5bx+2/NPPCnA10bOddQq2CzohK8UE3DdvYsO6Tu8EAvyJeK98ySOBjmJ2LhsbcVD8lo82fg/05c9f/iEVEfyokwCwWPoDZQ2iCMhuSdNMhG5SSzUALzwzCvWTJ6j+FzVhhlbaDRNHNHRb1Lo5uy3GNCLo2c6FEkxWzkRuARZqRsp1TVxa1+mpQmsG4EfiS6Ac1uwzZetvFps1a8LlkJs6MgAvMv1GsrveQQnSROTdcjM9BvAUje9p6AUleBVSGoEoQk30AnJs5bOrDS9TMx21DPgFr2aAEbT3ylYAAAAASUVORK5CYII=");
}

#input-modal .list-group-item.note-content:focus-within .add-content {
    display: block;
}

#input-modal .list-group-item.note-content > :not(.add-content) {
    border-bottom: 1px solid #343a40;
}
