/* 
    masonry container
    https://github.com/paulcollett/react-masonry-css  
*/
.masonry-grid {
    --gutter: 1rem;

    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    margin-left: calc(0rem - var(--gutter));
    width: auto;
}

.masonry-grid .masonry-col {
    padding-left: var(--gutter);
    background-clip: padding-box;
}

.masonry-grid .masonry-col > div {
    margin-bottom: var(--gutter);
}
